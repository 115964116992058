<template>
  <div>
    <h4>Admin Dashboard</h4>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="percentage"
          type="number"
          label="Order Percentage"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="ytVideo"
          label="Home Page Youtube Video Link"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="guidePic"
          label="Guide Background Image Link"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <v-btn @click="update()">Update</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import firebase from "firebase";
export default {
  data() {
    return {
      percentage: 0,
      ytVideo: "",
      guidePic: "",
    };
  },
  methods: {
    update() {
      firebase.firestore().collection("settings").doc("global").update({
        ytVideo: this.ytVideo,
        percentage: this.percentage,
        guidePic: this.guidePic,
      });
    },
  },
  mounted() {
    console.log('asd');
    this.$nextTick(() => {
      var _ = this;
      firebase
        .firestore()
        .collection("settings")
        .doc("global")
        .get()
        .then((res) => {
          var o = res.data();
          _.percentage = o.percentage;
          _.ytVideo = o.ytVideo;
          _.guidePic = o.guidePic;
        });
    });
  },
};
</script>